import { Alert, Container, Skeleton } from "@mui/material";
import { IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/mui";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { FunctionComponent, useEffect, useState } from "react";

const axios = require("axios").default;

type BoxRequestFormProps = {
  onSubmitSuccess: (boxRequest: any) => void;
};

const BoxRequestForm: FunctionComponent<BoxRequestFormProps> = (props) => {
  const [uiSchema, Set_uiSchema] = useState<UiSchema | undefined>(undefined);
  const [uiSchemaError, Set_uiSchemaError] = useState<any | undefined>(
    undefined
  );
  const [schema, Set_schema] = useState<RJSFSchema | undefined>(undefined);
  const [schemaError, Set_schemaError] = useState<any | undefined>(undefined);
  const [formData, Set_formData] = useState<any | undefined>(undefined);
  const [submitError, Set_submitError] = useState<any | undefined>(undefined);
  const [acceptTerms, Set_acceptTerms] = useState<boolean>(false);
  const [extraErrors, Set_extraErrors] = useState<any>({});

  function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/bridge/box-ui-schema/")
      .then((res: any) => Set_uiSchema(res.data))
      .catch((e: any) => Set_uiSchemaError(e));
    axios
      .get(process.env.REACT_APP_API_URL + "/bridge/box-schema/")
      .then((res: any) => Set_schema(res.data))
      .catch((e: any) => Set_schemaError(e));
  }, []);

  useEffect(() => {
    if (submitError !== undefined) {
      Set_extraErrors({});
      if (typeof submitError === "string" || submitError instanceof String) {
        //TODO show error toast
      } else if (typeof submitError[0] === "undefined") {
        for (const [key, value] of Object.entries(submitError)) {
          Set_extraErrors((prev: any) => {
            return {
              ...prev,
              [key]: { __errors: [value] },
            };
          });
        }
      }
    }
  }, [submitError, extraErrors]);

  const onSubmit = (data: IChangeEvent) => {
    Set_submitError(undefined);
    axios
      .post(
        process.env.REACT_APP_API_URL + "/bridge/box-request/",
        data.formData
      )
      .then((res: any) => props.onSubmitSuccess(res.data))
      .catch((e: any) => {
        if (e.response.data) {
          Set_submitError(e.response.data);
        } else {
          Set_submitError(e);
        }
        window.scrollTo(0, 0);
      });
  };

  return (
    <Container>
      {uiSchemaError !== undefined && (
        <Alert severity="error" className="mt-3">
          {JSON.stringify(uiSchemaError)}
        </Alert>
      )}
      {schemaError !== undefined && (
        <Alert severity="error" className="mt-3">
          {JSON.stringify(schemaError)}
        </Alert>
      )}
      {submitError !== undefined && (
        <Alert severity="error" className="mt-3">
          {Object.keys(extraErrors).length > 0
            ? "Please fix the below error(s) in order to proceed"
            : "An unexpected error occurred when submitting"}
        </Alert>
      )}
      {uiSchemaError === undefined && schemaError === undefined && (
        <>
          {uiSchema === undefined || schema === undefined ? (
            <>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={`${getRandomInt(10, 100)}%`}
                  height={40}
                  className={"form-field-skeleton"}
                />
              ))}
            </>
          ) : (
            <Form
              uiSchema={uiSchema}
              schema={schema}
              validator={validator}
              formData={formData}
              onChange={(data) => Set_formData(data.formData)}
              onSubmit={onSubmit}
              onError={(errors) => {}}
              extraErrors={extraErrors}
              showErrorList={false}
              noHtml5Validate
            >
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="acceptTerms"
                  defaultChecked={false}
                  onChange={() => Set_acceptTerms(!acceptTerms)}
                />
                <label className="form-check-label" htmlFor="acceptTerms">
                  By proceeding, I agree to the{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="/terms.html"
                  >
                    terms of service
                  </a>
                </label>
              </div>
              <button
                className="btn btn-primary"
                style={{ marginTop: "30px", width: "100%" }}
                type="submit"
                disabled={!acceptTerms}
              >
                Next
              </button>
            </Form>
          )}
        </>
      )}
    </Container>
  );
};

export default BoxRequestForm;
